import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';

import { AddIcon, ArticleIcon, DebtsIcon, MoneyIcon } from 'assets/icons';
import RelifLogo from 'assets/images/logo.webp';
import CajaLosAndesLogo from 'assets/images/tapp/caja_los_andes_logo.svg';
import PlayButtonIcon from 'assets/images/tapp/play_button.svg';
import { checkIfTokenIsValid } from 'authStorage';
import useAccessToken from 'hooks/useAccessToken';
import useIsMobile from 'hooks/useIsMobile';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { useTappSignUpUserMutation } from 'services/api/user.api';
import getReCaptchaToken from 'utils/getReCaptchaToken';

import styles from './TappDashboard.module.scss';
import ChatButton from './components/ChatButton';

interface TitleProps {
  isMobile: boolean;
}

const Title: React.FC<TitleProps> = ({ isMobile }) => (
  <Typography variant="h1" fontSize={isMobile ? '40px' : '50px'}>
    Bienvenid@
  </Typography>
);

const DescriptionInfoText = () => (
  <Typography variant="body1">
    Soy tu Consejero Financiero Digital, acá podrás recibir consejos de educación financiera para optimizar tus finanzas
    personales. Si deseas, te podré dar recomendaciones prácticas que te ayudarán a comprender y evaluar temas
    financieros de tu interés, como ahorro, presupuestos, gestión de deudas y más.
  </Typography>
);

const ActionText = ({ pt }: { pt: number }) => (
  <Typography variant="h3" pt={pt}>
    Para comenzar, selecciona en qué podemos ayudarte 🚀
  </Typography>
);

const Footer = ({ isMobile }: { isMobile: boolean }) => (
  <Box className={styles.Footer}>
    <img src={CajaLosAndesLogo} alt="Caja Los Andes Logo" width={isMobile ? '100px' : '140px'} />
    <img src={RelifLogo} alt="Relif Logo" width={isMobile ? '100px' : '140px'} style={{ marginBottom: '1rem' }} />
  </Box>
);

const ChatButtons = ({ handleClick }: { handleClick: (message: string) => Promise<void> }) => (
  <Grid container>
    <ChatButton Icon={DebtsIcon} handleClick={() => handleClick('Hola, quiero ayuda con mis deudas')} text="Deudas" />
    <ChatButton
      Icon={MoneyIcon}
      handleClick={() => handleClick('Hola, quiero ayuda con ahorro e inversión')}
      text="Ahorro e inversión"
    />
    <ChatButton
      Icon={ArticleIcon}
      handleClick={() => handleClick('Hola, quiero ordenar mis finanzas')}
      text="Orden financiero"
    />
    <ChatButton
      Icon={AddIcon}
      handleClick={() => handleClick('Hola, quiero ayuda con otro tema financiero')}
      text="Otro"
    />
  </Grid>
);

const PlayButton = () => (
  <Box className={styles.PlayButton}>
    <Box className={styles.Header}>
      <Typography variant="body1" color="white" fontWeight="bold">
        Video explicativo
      </Typography>
    </Box>
    <Box className={styles.Play}>
      <img src={PlayButtonIcon} alt="Play Button" />
    </Box>
  </Box>
);

const DisclaimerText = ({ mt }: { mt: number }) => (
  <Typography variant="body1" mt={mt}>
    Nuestro consejero financiero:
    <li>Nunca te pedirá ni hará uso de tu dinero</li>
    <li>Nunca te obligará a solicitar nada que tu no desees</li>
    <li>Jamás te pedirá ni hará uso de tu dinero</li>
    <li>Jamás te ofrecerá dinero directamente</li>
    <li>Nunca podrá solicitar ni tramitar un crédito, una portabilidad o abrir una cuenta a tu nombre</li>
    <li>Solo tú decides qué hacer 😉</li>
  </Typography>
);

const TappDashboard = () => {
  const [selectedMessage, setSelectedMessage] = React.useState('');
  const [signUp, { data, isLoading }] = useTappSignUpUserMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const token = useAccessToken();
  const tokenIsValid = checkIfTokenIsValid(token);

  const handleClick = async (messageText: string) => {
    setSelectedMessage(messageText);
    if (isLoading) return;
    if (!tokenIsValid) {
      const recaptchaToken = await getReCaptchaToken();
      signUp({ recaptchaToken }).catch(() => {
        console.error('Error signing up'); // eslint-disable-line no-console
      });
    } else {
      navigate(`/chat?firstMessage=${messageText}&disableOnboarding=true`);
    }
  };

  useEffect(() => {
    if (data) {
      dispatch(setAccessToken(data));
    }
  }, [data]);

  useEffect(() => {
    if (tokenIsValid && selectedMessage) {
      navigate(`/chat?firstMessage=${selectedMessage}&disableOnboarding=true`);
    }
  }, [tokenIsValid, selectedMessage]);
  if (isMobile) {
    return (
      <Box className={styles.DashboardContainer}>
        <Box className={styles.Card} display="flex" flexDirection="column">
          <Box className={styles.DescriptionContainer}>
            <Box className={styles.Title}>
              <Title isMobile={isMobile} />
            </Box>
            <Box className={styles.DescriptionInfo}>
              <DescriptionInfoText />
              <ActionText pt={1} />
              <Footer isMobile={isMobile} />
            </Box>
          </Box>
          <Box className={styles.Illustration} />
        </Box>
        <ChatButtons handleClick={handleClick} />
        <Box className={styles.Card}>
          <PlayButton />
        </Box>
        <DisclaimerText mt={2} />
      </Box>
    );
  }
  return (
    <Box className={styles.DashboardContainer}>
      <Grid container>
        <Grid item xs={12}>
          <Box className={`${styles.Card} ${styles.MainCard}`}>
            <Grid container>
              <Grid item xs={4}>
                <Box className={styles.Illustration} />
              </Grid>
              <Grid item xs={8}>
                <Box className={styles.DescriptionContainer}>
                  <Box className={styles.Title}>
                    <Title isMobile={isMobile} />
                  </Box>
                  <Box className={styles.DescriptionInfo}>
                    <DescriptionInfoText />
                    <ActionText pt={3} />
                    <Footer isMobile={isMobile} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4} pr={4}>
              <Box className={styles.Card}>
                <PlayButton />
              </Box>
            </Grid>
            <Grid item xs={8} className={styles.ButtonGrid} my={3}>
              <ChatButtons handleClick={handleClick} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DisclaimerText mt={2} />
    </Box>
  );
};

export default TappDashboard;
