import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';

interface Props {
  companyIdentifier: string | undefined;
  children: JSX.Element;
  disableOnboarding?: boolean;
}

const ChatWrapper: React.FC<Props> = ({ companyIdentifier, children, disableOnboarding }) => {
  const navigate = useNavigate();
  const [getUser, user] = useLazyGetUserInformationQuery();

  const companyName = user?.data?.company?.name;

  const assistantName = companyName === 'Tapp' ? 'Consejero Virtual' : 'Asesor Virtual';

  const navigateToDashboard = () => {
    if (companyName === 'Buk') {
      navigate('/buk/dashboard');
    } else if (companyName === 'Tapp') {
      navigate('/cla/dashboard');
    } else {
      console.error(`Unknown company name: ${companyName}`); // eslint-disable-line no-console
    }
  };

  useEffect(() => {
    getUser(null).catch((e) => console.error(e)); // eslint-disable-line no-console
  }, []);

  return (
    <Box sx={{ height: { xs: '100vh' }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          borderBottom: '1px solid #e0e0e0',
          display: 'flex', // flex
          flexDirection: 'column',
          width: { xs: '100%', md: '60%' },
          flex: 1,
          pt: 1,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ flex: 10, alignItems: 'center' }}>
            <Typography color="white" variant="h6" textAlign="center">
              {companyIdentifier?.replaceAll('-', ' ').toUpperCase()}
            </Typography>
            <Typography color="white" variant="body1" textAlign="center">
              {assistantName}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, py: 1 }}>
              <PrimaryBtn onClick={navigateToDashboard}>Volver</PrimaryBtn>
              {!disableOnboarding && (
                <PrimaryBtn
                  onClick={() =>
                    navigate(`/onboarding/${companyName?.toLowerCase() || 'chat'}?redirectUrl=/chat?refetchFlag=1`)
                  }
                >
                  Escáner Financiero
                </PrimaryBtn>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: '100%', md: '60%' },
          backgroundColor: 'backgroundGrey.main',
          flex: 11,
          overflow: 'scroll',
        }}
      >
        <Box height="100%">{children}</Box>
      </Box>
    </Box>
  );
};

ChatWrapper.defaultProps = {
  disableOnboarding: false,
};

export default ChatWrapper;
